import * as React from 'react';
import { PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import CommonLayout from '../templates/CommonLayout';
import { StaticImage } from 'gatsby-plugin-image';
import { styled } from 'linaria/lib/react';

const Linker = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 5%;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const IndexPage: React.FC<PageProps<GatsbyTypes.Query>> = ({ data }) => {
  const device = useBreakpoint();
  const isSmallViewer = device.m;

  const openGokita = () => window.open('https://jinbocho-gokita.com', '_self');
  const openEC = () => window.open('https://foretriziere.base.shop/', '_self');

  return (
    <CommonLayout title="forêt rizière">
      <div style={{ margin: '5vh auto' }}>
        <h1 style={{ fontFamily: 'var(--font-en-serif)', textAlign: 'center', marginBottom: '5vh' }}>forêt rizière</h1>
        <p style={{ textAlign: 'center' }}>
          東京神保町に店を構えるフレンチｘ焼鳥のレストラン
          <br />
          <span style={{ fontWeight: 'bolder' }}>[神保町 五木田]</span>
          <br />
          オンラインシャルキュトリーショップ
          <br />
          <span style={{ fontWeight: 'bolder' }}>[Boucherie Tokyo ブーシェリートーキョー]</span>
          <br />
          を運営しております。
        </p>
      </div>
      <div style={{ margin: '5vh 10vw', display: 'flex', flexDirection: isSmallViewer ? 'column' : 'row' }}>
        <Linker onClick={openGokita}>
          <StaticImage src="../assets/images/gokita.png" alt="神保町　五木田" />
          <p style={{ textAlign: 'center' }}>神保町　五木田</p>
        </Linker>
        <Linker onClick={openEC}>
          <StaticImage src="../assets/images/boucherietokyo.png" alt="Boucherie Tokyo ブーシェリートーキョー" />
          <p style={{ textAlign: 'center' }}>Boucherie Tokyo ブーシェリートーキョー</p>
        </Linker>
      </div>
    </CommonLayout>
  );
};

export default IndexPage;
